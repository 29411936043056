import React from "react"
import { Container, Row, Col } from 'reactstrap'
import Layout from "../components/layout"
import { MessengerChat } from '../components/messengerChat'
import Button from "../components/btn"
import SEO from "../components/seo"
import { FaHome } from 'react-icons/fa'
import { MdShoppingCart, MdSettingsBackupRestore, MdThumbUp, MdSecurity, MdDeviceHub, MdImportContacts } from "react-icons/md";
import Slider from '../components/slider'
import Box from '../components/box'
import Benefits from '../components/benefits'
import styled from 'styled-components'

let StyledBackground = styled.div`
  background: linear-gradient(to bottom,#f9fbfd 0,#fff 100%);
`

let Service = ({title, content, color, Icon = FaHome}) => (
  <Col xs={12} md={4} className="mb-3">
      <Box style={{'borderColor': color}}>
        <Icon size={30}/>
        <h4 className="mt-3">{title}</h4>
        <p className="text-muted">{content}</p>
      </Box>
  </Col>
)

let Index = () => (
  <Layout>
    <MessengerChat/>
    <SEO title='Home'></SEO>
    <Slider/>
    <Container className="pt-4">
    </Container>
    <Container className="py-5">
      <h2 className="text-center mb-5">Servizi</h2>
      <Row>
        <Service title="E-commerce e Siti Web" content="Sviluppo di siti web e e-commerce personalizzati" color="#f2d100" Icon={MdShoppingCart}/>
        <Service title="Reti e infrastrutture" content="Sviluppo e supporto nella gestione di infrastrutture di rete" color="#ffa500" Icon={MdDeviceHub}/>
        <Service title="Security e protezione" content="Installazione di firewall e antivirus di ultima generazione" color="#e02b20" Icon={MdSecurity}/>
        <Service title="Software gestionali" content="Vendita e assistenza di software gestionali per le aziende" color="#0c71c3" Icon={MdImportContacts}/>
        <Service title="Verifiche misuratori fiscali" content="Siamo certificati ISO 9001 per la verifica periodica di misuratori fiscali (EA 35)" color="#e800ae" Icon={MdThumbUp}/>
        <Service title="Backup e ripristino dati" content="Installazione di sistemi di backup e ripristino dati" color="#63af16" Icon={MdSettingsBackupRestore}/>
      </Row>
    </Container>
    <StyledBackground>
      <Benefits />
        <div className="text-center py-3 mb-5">
        <Button to="/business-cube" className="btn btn-primary btn-lg">
          Scopri Business Cube
        </Button>
        </div>
    </StyledBackground>
    {/* <HomeFeatures/> */}


  </Layout>
)

export default Index