import React from 'react'
import {Container, Row, Col} from 'reactstrap'
import { MdDone } from 'react-icons/md'
import Img from 'gatsby-image/withIEPolyfill'
import styled from 'styled-components'
import "../assets/stylesheets/components/benefits.scss"

let Benefit = ({title, content}) => (
  <div className="benefits__container d-flex mb-4">
    <MdDone size={30} className="text-primary"/>
    <div className="benefits__inner ml-3">
      <h4>{title}</h4>
      <p className="m-0 text-muted">{content}</p>
    </div>
  </div>
)

let Benefits = ({data}) => (
  <Container id='business-cube' className="py-4">
    <Row className="d-flex align-items-center">
      <Col md="6">
        <div className="mb-4">
          <h2 className="text-primary">Business Cube <span className="">di NTS Informatica</span></h2>
          <p className="text-muted">Tecnologia e funzionalità d’avanguardia in un’unica soluzione gestionale.</p>
        </div>
        <Benefit title="Elevata copertura funzionale" content="Business Cube mette a tua disposizione un’ampia gamma di funzionalità, in grado di rispondere con efficacia alle differenti esigenze gestionali della tua azienda: amministrazione, controllo di gestione, magazzino, logistica, produzione, CRM, customer service, funzionalità social e molte altre.. "/>
        <Benefit title="Massima flessibilità" content="Business Cube è un'applicazione dotata di architettura server-based proprietaria, disponibile nelle versioni 'On Premise' (con licenza definitiva) oppure 'Software as a Service', con la possibilità di accedere alle funzioni gestionali anche grazie a una specifica App o via Web."/>
        <Benefit title="Design responsive" content="Business Cube si presenta con un design responsive e adaptive, oggi utilizzato anche dai più recenti siti web, che rende la sua interfaccia capace di adattarsi alle dimensioni del tuo computer, smartphone e tablet, riconoscendo il dispositivo che stai usando per la fruizione del programma."/>
      </Col>
      <Col md="6">
      </Col>
    </Row>
  </Container>
)


export default Benefits
